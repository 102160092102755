import React, { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { Box, ThemeProvider, createTheme } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import offerImg from '../../assets/offer-banner/cupon-code-LAUNCH5.png'
import {
  setToLocalStorage,
  getFromLocalStorage,
  removeTags,
} from "../../helpers/helpers";
import { getSeo } from '../../api'
import { useSelector, useDispatch } from "react-redux";
import './homeCommon.css'
import Layout from '../../components/layout/Layout'
import BannerSection from '../bannerSection/BannerSection'
import AllProducts from '../allproducts/AllProducts'
import DiscoverMoreBestsellers from '../discoverMoreBestsellers/DiscoverMoreBestsellers'
import OfferPage from '../offerPage/OfferPage'
import Testimonial from '../../components/testimonial/Testimonial'
import Category from '../category/Category'
import NewArrivals from '../newArrivals/NewArrivals'
import ExclusiveCollections from '../exclusiveCollections/ExclusiveCollections'
import {Helmet} from "react-helmet";

 

const theme = createTheme({})


const HomeMemorize = () => {
  const dispatch = useDispatch();
  const [openMod, setOpenMod] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getSeo())
    .then(res => {
      res.message.map((item)=>{
        //setToLocalStorage(item.
      })
    })
  }, []);
  
  const handleClickOpen = () => {
    setOpenMod(true);
  };

  const handleClose = () => {
    setOpenMod(false);
  };
  React.useEffect(() => {
    handleClickOpen()
  },[])
  return (
    <Layout>
        <Dialog
          open={openMod}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className='bg-[#ffde00]'>
            <DialogContentText id="alert-dialog-description">
              <img src={offerImg} alt="" className="" />
              <div className="text-center mt-2">
                <Button className='' size="small" variant="contained" onClick={() => {handleClose(); navigate("/productlist")}}>
                  Shop Now
                </Button>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>

      <BannerSection />
      <Category/>
      {/* title */}
      {/* <NewArrivals/> */}
      <AllProducts />
      <ExclusiveCollections/>
      <OfferPage/>
      <DiscoverMoreBestsellers/>
      <Testimonial/>
    </Layout>
  )
}
const Home = memo(HomeMemorize)
export default Home