import React,{ useState, memo, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NewArrivalsLogo from '../../assets/Logo/NewArrivals.png'

import Mabesha1 from '../../assets/ProductGallary/Mabesha2379.jpg';
import Mabesha2 from '../../assets/ProductGallary/Mabesha2395.jpg';
import Mabesha3 from '../../assets/ProductGallary/Mabesha2411.jpg';
import Mabesha4 from '../../assets/ProductGallary/Mabesha2415.jpg';
import Mabesha5 from '../../assets/ProductGallary/Mabesha2427.jpg';
import Mabesha6 from '../../assets/ProductGallary/Mabesha2436.jpg';
import Mabesha7 from '../../assets/ProductGallary/Mabesha2644.jpg';
import Mabesha8 from '../../assets/ProductGallary/Mabesha2466.jpg';
import axios from 'axios';
import { apiBaseUrl } from '../../config/config';
import { removeTags, setToLocalStorage } from "../../helpers/helpers";

const AllProductsMemorized = () => {
  const [productPost, setProductPost] = useState([Mabesha1, Mabesha2, Mabesha3, Mabesha4, Mabesha5, Mabesha6, Mabesha7, Mabesha8])
  const [imgPath, setImgPath] = useState()
  const [productList, setProductList] = useState([])
  const navigate = useNavigate()
  const getNewArrivel = () =>{
  
    axios.get(`${apiBaseUrl}api/product-list`)
    .then((res) => {
      setImgPath(res.data.img_thumb_path);
      setProductList(res.data.message);
      setToLocalStorage("allProductData",JSON.stringify(res.data))
      console.log("this is nre arraivel data", res)
    
    })
    .catch((err) => {
      console.error(err);
    });
  }
  useEffect(()=>{
    getNewArrivel()
  },[])

  const newArrivalData = productList.filter(item => item.is_new_arrivals === 1);

  const setProductdataInLocalstorage = (id,product_name,color_name) => {
    const prodTitle = product_name
    const prodColor = color_name
    const slug = ((prodTitle)? prodTitle : "").replace(/\W+/g, '-') +((prodColor)? "-"+prodColor : "").replace(/\W+/g, '-')+'-'+id
    const titleToLink = "saree/"+slug.toLowerCase();
    localStorage.setItem("ProductId", id)
    navigate(titleToLink);
  }

  return (
    <>
      <div className='flex justify-center mt-12'>
          {/* <img src={NewArrivalsLogo} alt='newArrivals' /> */}
          <h1 className='font-Lucida text-[2em] md:text-[3em] font-medium text-red-600 red-title-shadow'>New Arrivals</h1>
      </div>
      <div className='my-10'>
        <div className="flex items-center justify-center  bg-white">
          <div className="grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-4">
            {
              newArrivalData?.map((item, index) => {
                // https://mabesha.com/admin/storage/product/product_logoproduct_image/1719923841_Mabesha3419.jpg.webp?width=400&sharpen=true
                const newImg = item.product_image ? item.product_image.split(",") : [];
                var imgName = `product_image/${newImg[0]}`;
                // console.log("This is detail",newArrivalData?.product_detail)
                return (
                  <div className="group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30" key={index}>
                    <div className="h-96 w-72">
                      {/* <img loading='lazy' className="h-full w-full rounded-lg object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={`${imgPath}/${newImg[0]}` } alt="" /> */}
                      <img loading='lazy' className="h-full w-full rounded-lg object-cover transition-transform duration-500 group-hover:rotate-3 group-hover:scale-125" src={`https://mabesha.com/admin/storage/product/product_logo${imgName}?width=400&sharpen=true` } alt="" />
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                    <div className="absolute inset-0 flex translate-y-[60%] flex-col items-center justify-center px-9 text-center transition-all duration-500 group-hover:translate-y-0">
                      <p className="mb-3 text-lg italic text-white opacity-0 transition-opacity duration-300 group-hover:opacity-100"  dangerouslySetInnerHTML={{__html:item?.product_detail}}></p>
                     <button onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name)} className="rounded-full bg-neutral-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60">See More</button>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className=' flex justify-center mt-10'>
         <Link to='/productlist'> <button className='btn bg-[#DF2419] sdh text-white max-w-[380px] text-lg h-[50px] shrink-0  shadow-xl font-thin hover:text-red-500 uppercase'>View All product</button></Link>
        </div>
      </div>
    </>
  )
}
const AllProducts = memo(AllProductsMemorized)
export default AllProducts