import React, { memo, useEffect, useState } from 'react'
import { Link, useNavigate, useParams, } from 'react-router-dom'

import Layout from '../../components/layout/Layout'
import Loader from '../../components/Loding/Loding'

import {Helmet} from "react-helmet";
import Sheet from 'react-modal-sheet';
import { listProducts,listCategory } from '../../api'
import { useSelector, useDispatch } from "react-redux";
import { apiBaseUrl } from '../../config/config';
import { useCart } from "react-use-cart";
import {
  setToLocalStorage,
  getFromLocalStorage,
  removeTags,
} from "../../helpers/helpers";


const ProductListMemorize = () => {
  const sareeCategory = useParams();
  const dispatch = useDispatch();
  const [productList, setProductList] = useState(null)
  const [categoryList, setCategoryList] = useState(null)
  const navigate = useNavigate()
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [token, setToken] = useState(getFromLocalStorage("access_token"))
  const [imgPath, setImgPath] = useState()
  const [change, setChange] = useState(true)
  const [priceRange, setPriceRange] = useState(0)
  const [minRange, setMinRange] = useState(0)
  const [maxRange, setMaxRange] = useState(0)
  const [hoverStates, setHoverStates] = useState({});
  const [category, setCategory] = useState([]);
  const [valueRoute, setValueRoute] = useState("something");
  const [pageTitle, setPageTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [cart, setCart] = useState([]);
  const [itemAddedToCart, setItemAddedToCart] = useState(0);
  
  const { addItem,isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem } = useCart();  
  // const  addItem = useCart();
    
  function addMyItem(item) {
    var p= item
    p.price=parseInt(item.product_rate)
    p.quantity=0
    addItem(p)
  }

  useEffect(() => {
    setItemAddedToCart(itemAddedToCart+1)
  }, [items]);

  var productKeys = {};

  let active = "text-white border bg-red-600 text-xs p-1 rounded-full"
  let deactive = "text-red-600 border border-red-600 text-xs p-1 rounded-full"
  let commmen = "text-red-600 border border-red-600 text-xs p-1 rounded-full"
  const handleMouseEnter = (index) => {
    setHoverStates({ ...hoverStates, [index]: true });
  };
  const handleMouseLeave = (index) => {
    setHoverStates({ ...hoverStates, [index]: false });
  };
  const setProductdataInLocalstorage = (id) => {
    localStorage.setItem("ProductId", id)
    // navigate('/productDetails')
    navigate(`/${id}`)
  }
  useEffect(() => {
    getProducts();
    getCategory();
  }, []);

  
  const findCartById = (id) => {
    return ((items?.find(c=>c.id===id))?items?.find(c=>c.id===id)?.quantity:0);
  };
  
  const insertItem = (id) => {
    if(cart){
      const c= [...cart,{prodid:id,count:1}]
      setCart(c)
    }else{
      const c= [{prodid:id,count:1}]
      setCart(c)
    }
  };

  const updateCart = (id, newCount) => {
    if(newCount==0){
      //removeItem(id);
    }else{
      if(findCartById(id)){

      }
      else
        insertItem(id);
      console.log(cart);
    }
  };
  
  const priceRangeUpdated = (range) => {
    setPriceRange(range.target.value);
  };

  const getProducts = () => {
    dispatch(listProducts())
      .then(res => {
        setImgPath(res.img_thumb_path);
        setProductList(res.message);
        setMinRange(res.minrate);
        setMaxRange(res.maxrate);
        setPriceRange(res.maxrate);
      })
      .catch(() => {

      })
  }

  const getCategory = () => {
    dispatch(listCategory())
      .then(res => {
        setCategoryList(res);
      })
      .catch(() => {

      })
  }

  const selectCategory = (selectItem) => {
    setCategory(currentCategory => {
      // Ensure currentCategory is always an array
      if (!Array.isArray(currentCategory)) {
        console.error("currentCategory is not an array:", currentCategory);
        return [];
      }
  
      if (currentCategory.includes(selectItem)) {
        // If the category is already selected, remove it
        return currentCategory.filter(item => item !== selectItem);
      } else {
        // If the category is not selected, add it
        return [...currentCategory, selectItem];
      }
    });
  }
  
  let filteredProducts;
  if (category.length === 0) {
    filteredProducts = productList?.filter(product => priceRange>=parseFloat(product.product_rate));
  } else {
    filteredProducts = productList?.filter(product => category.includes(product?.cat_id)&&priceRange>=parseFloat(product.product_rate));
  }
  
 
  const isCategorySelected = (catId) => {
    return category.includes(catId);
  }

  const getCategorySlug = (catId) => {
    var name = categoryList?.find(cat=>cat.id===catId).category_name;
    return convertToSlug(name);
  }

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  useEffect(() => {
    
    if (sareeCategory.id){
      
      var cat_id = categoryList?.find(cat=>convertToSlug(cat.category_name) ===convertToSlug(sareeCategory.id)).id;
      setPageTitle("Collections | "+categoryList?.find(cat=>convertToSlug(cat.category_name) ===convertToSlug(sareeCategory.id)).category_name);
      setMetaTitle(categoryList?.find(cat=>convertToSlug(cat.category_name) ===convertToSlug(sareeCategory.id)).meta_title)
      setMetaDescription(categoryList?.find(cat=>convertToSlug(cat.category_name) ===convertToSlug(sareeCategory.id)).meta_desc)
      setCategory([parseInt(cat_id)]);
    }else{
      setPageTitle("Mabesha Collections")
      setMetaDescription("Explore Excuisite Collections of Mabesha's Premium Sarees")
      setCategory([]);
    }
  }, [sareeCategory,categoryList])
  

  return (
    <Layout >
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={metaDescription}></meta>
            <meta property="og:title" content={metaTitle}></meta>
        </Helmet>
      { window.innerWidth <= 768 ? 
          <div className="w-full">
            <div className="block text-end px-5 mb-2 mt-10">
              <button className="btn" onClick={() => setOpenFilter(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 mr-2">
                  <path fillRule="evenodd" d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z" clipRule="evenodd" />
                </svg>
                Filter 
              </button>
            </div>
          </div>
          :
          <></>
      }
      <div className={`w-full flex ${itemAddedToCart}`}  >
        { window.innerWidth <= 768 ? 
          <></>
          :
          <>
          <div className='w-[20%]  bg-[#F9F9F9] h-auto'>
            <div className='flex justify-between pt-5 px-4  border-b-2 pb-5 border-yellow-600'>
              <div className='flex space-x-2 '>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                  <path fillRule="evenodd" d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z" clipRule="evenodd" />
                </svg>
                <p className='text-sm font-semibold'>Filters</p>
                <p className='text-white text-xs w-3 mt-1 rounded-full flex justify-center items-start h-4 bg-black'>1</p>
              </div>
              <p className='text-xs font-semibold text-red-600 cursor-pointer' onClick={() => setCategory([])}>Clear All</p>
            </div>

            <div className="srchFilter-item pt-5 px-4">
              <div className='flex justify-between'>
                <h1 className="font-bold  py-2 ">By Saree Type </h1>
                <h1 className="font-bold  py-2 ">-</h1>
              </div>
              <ul className="cat-list flex flex-wrap gap-2">
                {categoryList?.map((item,index)=>{
                  return(
                  <li key={index} className='cat-list-item'>
                    {/* px-4 py-2 space-x-3 */}
                    <button className={` ${isCategorySelected(item.id) ? active : deactive}${commmen}`} onClick={() => selectCategory(item.id)}>{item.category_name} {`${isCategorySelected(item.id) ? "-" : "+"} `}</button>
                  </li>
                  )
                })}
              </ul>
            </div>
            
            <div className="srchFilter-item pt-5 px-4">
              <div className='flex justify-between'>
                <h1 className="font-bold  py-2 ">By price (₹{priceRange})</h1>
                <h1 className="font-bold  py-2 ">-</h1>
              </div>
              <div className='flex justify-around'>
                <input
                className="transparent bg-red-600 h-[4px] w-full cursor-pointer appearance-none border-transparent  "
                type='range'
                min={minRange}
                max={maxRange}
                step="1"
                value={priceRange}
                onInput={priceRangeUpdated}
                onChange={priceRangeUpdated} />
              </div>
            </div>
          </div>
          </>
        }
        <div className={`${window.innerWidth <= 768 ? '' : 'w-[80%] h-auto'} bg-[#F9F9F9]  `}>

          {filteredProducts && filteredProducts.length === 0 && (
            <div className='w-full h-screen flex justify-center items-center'>
              No products available
            </div>
          )}
          {/* <div className='grid grid-cols-5 gap-4 p-5  justify-center items-center'> */}
            {filteredProducts ? (
              <div className='grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 gap-4 p-5  justify-center'>
                {
                  filteredProducts?.map((itemList, index) => {

                    const newImg = itemList.product_image ? itemList.product_image.split(",") : [];
                    let grayscale = 1;
                    let brightness = 0.8;
                    let opacity = 0.3;
                    // console.log(itemList)
                    // console.log(newImg)
                    const category_slug= "/collections/"+getCategorySlug(itemList.cat_id)+"/"
                    const prodTitle = itemList.product_name
                    const prodColor = itemList.color_name
                    const prodIdEnc = window.btoa(itemList.id)
                    const prodIdEncAgn = window.btoa(prodIdEnc+'A2zWC')
                    const slug = ((prodTitle)? prodTitle : "").replace(/\W+/g, '-') +((prodColor)? "-"+prodColor : "").replace(/\W+/g, '-')+'-'+itemList.id
                    // const prodIdDyc = window.atob(prodIdEnc)
                    const titleToLink = category_slug+"saree/"+slug.toLowerCase();
                    var quantity=((items?.find(c=>c.id===itemList.id))?items?.find(c=>c.id===itemList.id)?.quantity:0)
                    const encProdId = titleToLink.match(/[^-]*$/)[0]
                    if(quantity>0)
                      console.log(itemList.id+" id quantity: "+quantity+",added at position: "+index)
                    // const encoded = encodeURIComponent(titleToLink);
                    // console.log(prodIdEncAgn, encProdId)
                    const ProdLinkKey = []
                    /* itemList.forEach((data, index) => {
                      console.log(index)
                      // const ProdLinkKey.push({encProdId: prodIdEncAgn})
                    }); */
                    // setToLocalStorage("allProductData",JSON.stringify(res.data))
                    // setToLocalStorage("accessProdLinkKey",JSON.stringify(ProdLinkKey))
                    const ProdLinkKey1 = {encProdId: prodIdEncAgn}
                    // localStorage.setItem("accessProdLinkKey2",JSON.stringify(ProdLinkKey1))
                    // console.log(titleToLink)
                    productKeys[index] = {key: prodIdEncAgn, lock: encProdId}
                    localStorage.setItem("accessProdLinkKey2",JSON.stringify(productKeys))
                    setToLocalStorage("accessProdLinkKey",JSON.stringify(productKeys))
                    // console.log(productKeys)
                    
                    return (
                      <div key={index} className={`flex flex-col ${itemList.id} `} /* onClick={() => setProductdataInLocalstorage(itemList.id)} */ >
                        <div className="relative wrapProdImg">
                        <div>
                          <Link to={{ pathname:`/${titleToLink}`, state: valueRoute }}>
                          <img
                              onMouseEnter={() => handleMouseEnter(index)}
                              onMouseLeave={() => handleMouseLeave(index)} // 
                              // className='h-72 w-48 rounded-md cursor-pointer'
                              className='min-w-full rounded-md cursor-pointer'
                              src={hoverStates[index] ? `${imgPath}/${newImg[0]}` : `${imgPath}/${newImg[1]}`}
                              
                              alt="productlist"
                              /* style={itemList?.is_stock_available == 0 ? {
                                filter: `grayscale(${grayscale}) brightness(${brightness}) opacity(${opacity})`
                              } : {}} {id:0,quantity:0}  */ 
                            ></img>
                          </Link>
                        </div>
                        
                          <div id={`${itemList.id}buttons`}>

                            {itemList?.is_stock_available !== 0 ?
                              <>
                                {
                                findCartById(itemList.id)<=0? 
                                <div >
                                    <button id={itemList.id+"button"}
                                      // onClick={() => addItem(itemList.id,1)}
                                      onClick={() => addMyItem(itemList)} type="button"
                                      className="text-sm bg-red-600 text-white font-semibold p-2 rounded-md w-32 flex  justify-around mt-3"
                                    >
                                      {" "}
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5" >
                                        <path fillRule="evenodd" d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z" clipRule="evenodd" />
                                      </svg>
                                      Add To cart
                                    </button>
                                </div>
                                
                              :
                              <div>
                                  <div className="mt-3 flex items-center">
                                    <button
                                      onClick={() => updateItemQuantity(itemList.id,findCartById(itemList.id)-1)} 
                                      className="minus bg-red-500 border border-gray-300 rounded-md w-8 h-8 text-white focus:outline-none">-</button>
                                        <p className="w-8 h-8 text-center text-xl rounded-md focus:outline-none">{findCartById(itemList.id)}</p>
                                        {
                                          findCartById(itemList.id)<itemList.product_count? 
                                          <button onClick={() => {
                                            updateItemQuantity(itemList.id,findCartById(itemList.id)+1)
                                          }}
                                          className="plus bg-red-500 border border-gray-300 rounded-md w-8 h-8 text-white focus:outline-none"
                                        >+</button>:<button disabled
                                        className="text-sm bg-yellow-600 text-white font-semibold p-2 rounded-md w-32 flex  justify-around"
                                    >
                                      {" "}
                                      No Stock
                                    </button>}
                                  </div>
                              </div>
                              
                                }
                                  
                              </>
                              : 
                              <button disabled
                                className="text-sm bg-gray-600 text-white font-semibold p-2 rounded-md w-32 flex  justify-around mt-3"
                              >
                                {" "}
                                Sold out
                              </button>
                            }
                          </div>

                        </div>
                        
                        {/* <p className='mt-1 font-Century  text-[15px] text-[#5C5C5C] uppercase '>{(itemList?.product_name).substring(0,20)}</p>
                        <span>{itemList?.id}-</span>
                        <span>{(imgPath)}</span> */}
                        {/* <span>{(encoded)}</span> */}
                        <h5 className='mt-1 font-poppins font-medium tracking-wide text-[1em] text-[#5C5C5C] '>
                          {/* <Link to={{ pathname:`/${titleToLink}`, state: { data: 'productKeys[index]' } }}>{(itemList?.product_name).substring(0,35)}</Link> */}
                          <Link to={{ pathname:`${titleToLink}`, state: valueRoute }} className=''>{(itemList?.product_name)}</Link>
                        </h5>
                        <p className=' font-Century  text-[1.2em] text-[#5C5C5C] uppercase font-bold'>₹{itemList?.product_rate}</p>

                    
                      </div>
                    );
                  })
                }
              </div>
            ) : (
              <div className='flex align-items-center justify-center p-5 h-full items-center'>
                {/* <div className='w-full'> */}
                  <Loader />
                {/* </div> */}
              </div>
            )}
        </div>
      </div>
      <Sheet isOpen={isOpenFilter} snapPoints={[600, 200]} onClose={() => setOpenFilter(false)}>
        <Sheet.Container>
          <Sheet.Header />
            <Sheet.Content>
            <Sheet.Scroller>
            {/* Your sheet content goes here */}
            <div className='flex justify-between pt-5 px-4  border-b-2 pb-5 border-yellow-600'>
              <div className='flex space-x-2 '>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                  <path fillRule="evenodd" d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z" clipRule="evenodd" />
                </svg>
                <p className='text-sm font-semibold'>Filters</p>
                <p className='text-white text-xs w-3 mt-1 rounded-full flex justify-center items-start h-4 bg-black'>1</p>
              </div>
              <p className='text-xs font-semibold text-red-600 cursor-pointer' onClick={() => setCategory([])}>Clear All</p>
            </div>

            <div className="srchFilter-item pt-5 px-4">
              <div className='flex justify-between'>
                <h1 className="font-bold  py-2 ">By Saree Type </h1>
                <h1 className="font-bold  py-2 ">-</h1>
              </div>
              <ul className="cat-list flex flex-wrap gap-2">
                {categoryList?.map((item,index)=>{
                  return(
                  <li key={index} className='cat-list-item'>
                    {/* px-4 py-2 space-x-3 */}
                    <button className={` ${isCategorySelected(item.id) ? active : deactive}${commmen}`} onClick={() => selectCategory(item.id)}>{item.category_name} {`${isCategorySelected(item.id) ? "-" : "+"} `}</button>
                  </li>
                  )
                })}
              </ul>
            </div>
            
            <div className="srchFilter-item pt-5 px-4">
              <div className='flex justify-between'>
                <h1 className="font-bold  py-2 ">By price (₹{priceRange})</h1>
                <h1 className="font-bold  py-2 ">-</h1>
              </div>
              <div className='flex justify-around'>
                <input
                className="transparent bg-red-600 h-[4px] w-full cursor-pointer appearance-none border-transparent  "
                type='range'
                min={minRange}
                max={maxRange}
                step="1"
                value={priceRange}
                onInput={priceRangeUpdated}
                onChange={priceRangeUpdated} />
              </div>
            </div>
            </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </Layout>
  )
}
const ProductList = memo(ProductListMemorize)
export default ProductList


