import React, { memo, useState, useEffect, useRef } from "react";
import { Link,useParams, useSearchParams,Navigate ,useNavigate,useLocation} from 'react-router-dom'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactImageMagnify from 'react-image-magnify';
import Imgix from "react-imgix";


import {Helmet} from "react-helmet";

// import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { Swiper, SwiperOptions, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, Scrollbar } from 'swiper/modules';
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import clsx from 'clsx';
import { styled, css } from '@mui/system';
import { Modal as BaseModal } from '@mui/base/Modal';
import Button from '@mui/joy/Button';


import TopFlowers from "../../assets/Logo/topFlower.png";
import loadingAnim from "../../assets/loading-animation.gif";
import './dtlStyle.css';
import SingleProductFlower from "../../assets/Logo/singleProductFlowers.png";
// import ssingleProductDetails from "../../assets/Logo/productDetails1.png";
import seeSimilarSaree from "../../assets/Logo/See similar sarees.png";
import SeeSimilarSarees from "../seeSimilarSarees/SeeSimilarSarees";
import SubscribeForUpdate from "../subscribeForUpdate/SubscribeForUpdate";
import Fade from "react-reveal/Fade";
import Layout from "../../components/layout/Layout";

import { FaAngleLeft } from "react-icons/fa6";

import { useDispatch } from "react-redux";
import { productDetails } from '../../api'
import { addItem } from "../../redux/cartSlice";
import axios from "axios";
import { apiBaseUrl } from "../../config/config";
import { useCart } from "react-use-cart";
import {
  setToLocalStorage,
  getFromLocalStorage,
  removeTags,
} from "../../helpers/helpers";
import { NotificationManager } from "react-notifications";
import ZoomPop from "../../components/prodZoomPop/ZoomPop";

const ProductDetailsPageMemorize = (props) => {
  const productDetail = useParams();

  const [pageTitle, setPageTitle] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const navigate = useNavigate();
  const [img, setImg] = useState();//setSimilarProduct
  const [singleProduct, setSingleProduct] = useState();
  const [similarProduct, setSimilarProduct] = useState();
  const [imgUrl, setImgUrl] = useState();
  const [imgUrlMediumThumb, setimgUrlMediumThumb] = useState();
  const [imgUrlThumb, setimgUrlThumb] = useState();
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState(0);
  const [productName, setProductName] = useState();
  const [productMaterial, setProductMaterial] = useState();
  const [prodID, setId] = useState();
  const dispatch = useDispatch();
  const [token, setToken] = useState(getFromLocalStorage("access_token"));
  const [count, setCount] = useState(0);
  const [categoryId, setCategoryId] = useState(0);
  const [colour, setColour] = useState(null);
  
  const [clientHeight, getClientHeight] = React.useState()
  const [clientWidth, getClientWidth] = React.useState()
  const [openModal, setOpenModal] = React.useState(false);
  const handleModalOpen = () => {
    setOpenModal(true);
    const ratio = window.devicePixelRatio || 1;
    const w = window.innerWidth * ratio;
    const h = window.innerHeight * ratio;
    /* getClientHeight(h)
    getClientWidth(w) */
    getClientHeight(window.innerHeight)
    getClientWidth(window.innerWidth)

  };
  const handleModalClose = () => {
    setOpenModal(false);
    document.body.style = '';

  };

  // const [direction, setDirection] = useState('vertical');
  const zoomSlideRef = useRef(null);


  const { addItem,isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem } = useCart();  
  useEffect(() => {
    getProducts();
  }, [productDetail]);


  const getProducts = () => {
    var id= productDetail.product.match(/\d+$/)[0];
    dispatch(productDetails(id))
      .then(res => {
        console.log(res);
        setProduct(res);
      })
      .catch(() => {

      })
  }
  function addMyItem(item) {
    var p= item
    p.price=parseInt(item.product_rate)
    p.quantity=0
    addItem(p)
  }
  const findCartById = (id) => {
    console.log("thisisit"+items.find(c=>c.id===id)?.quantity)
    if(items?.find(c=>c.id===id))
      return items?.find(c=>c.id===id)?.quantity;
    else 
      return 0;
  };
  // console.log('')
  const setProduct = (saree) => {
    setSingleProduct(saree.message);
    setPageTitle("Saree | "+saree.message.product_name);
    setMetaTitle(saree.message.meta_title)
    setMetaDescription(saree.message.meta_desc)
    console.log('Hello World', saree.message);
    setImg(saree.message.product_image);
    setColour(saree.message.color_name);
    setProductMaterial(saree.message.product_material);
    // setId(res.data.message.id) ;
    setImgUrl(saree.img_path);
    setimgUrlThumb(saree.img_thumb_path);
    setimgUrlMediumThumb(saree.product_detail_view);
    setCategoryId(saree.message.cat_id)
    setPrice(saree.message.product_rate);
    setProductName(saree.message.product_name);
    setSimilarProduct(saree.message.similar_products);
  }

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  const product = {
    product_id: `${prodID}`,
    qty: 2,
  };

  const handleAddToCart = (product) => {
    axios
      .post(`${apiBaseUrl}api/addtocart`, product, { headers })
      .then((res) => {
        console.log(res.data.message);
        NotificationManager.success(
          <p className="text-xs">{res.data.message}</p>
        );
      })
      .catch((err) => console.log("This is add to cart api call error", err));
  };

  // useEffect(() => {
  //   // getSingleProduct(localStorage.getItem("ProductId"));
  //   getSingleProduct()
  // }, []);

  const newImg = img ? img.split(",") : [];

  const pm = productMaterial ? productMaterial.split("<br />") : [];
  // console.log(imgUrlThumb+"something")
  // console.log(getProdId, "getProdId")


  
  // setDirection(newDirection);
  /*useEffect(() => {
    // setDirection('vertical')
    // const updateDirection = () => {
    //   const newDirection = window.innerWidth <= 768 ? 'horizontal' : 'vertical';
    //   setDirection(newDirection);
    //   // console.dir(zoomSlideRef.current)
    //   if (zoomSlideRef.current) { // Check if Swiper instance is created
    //     try {
    //       zoomSlideRef.current.swiper.changeDirection(newDirection)
    //       // console.log('Hello SD', zoomSlideRef.current.params)
    //       // zoomSlideRef.current.params.direction = newDirection;
    //       // zoomSlideRef.current.params.direction = newDirection;
    //       zoomSlideRef.current.swiper.update(true);
    //     } catch (error) {
    //       console.error('Error updating Swiper direction:', error);
    //     }
    //   }
    // };
    
    // if( window.innerWidth <= 768 ){
    //   updateDirection();
    //   window.addEventListener('resize', updateDirection);
  
    //   return () => window.removeEventListener('resize', updateDirection);
    // } 
   function detectBrowser() {
    var userAgent = navigator.userAgent;
    if (userAgent.indexOf("Edg") > -1) {
        return "Microsoft Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
        return "Chrome";
    } else if (userAgent.indexOf("Firefox") > -1) {
        return "Firefox";
    } else if (userAgent.indexOf("Safari") > -1) {
        return "Safari";
    } else if (userAgent.indexOf("Opera") > -1) {
        return "Opera";
    } else if (userAgent.indexOf("Trident") > -1 || userAgent.indexOf("MSIE") > -1) {
        return "Internet Explorer";
    }

    return "Unknown";
   }
 
    var browserName = detectBrowser();
    console.log('Saikat Test', browserName)
    // document.write("Your browser is: " + browserName);
  }, []); // Empty dependency array ensures it runs only once on mount*/








  return (
    <Layout className={window.innerWidth}> {/* title={`${productName}-${prodID}`} */}
    
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={metaDescription}></meta>
            <meta property="og:title" content={metaTitle}></meta>
        </Helmet>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={openModal}
        onClose={handleModalClose}
        closeAfterTransition
        // slots={{ backdrop: StyledBackdrop }}
      >
        {/* <Fade> */}
          <ModalContent sx={style} in={openModal}>
            <Button size="sm" variant="outlined" startDecorator={<FaAngleLeft />} onClick={handleModalClose}>
              Back - H:{clientHeight}, W:{clientWidth}
            </Button>
            <ZoomPop imgItem={newImg} imgBGPath={imgUrl} imgPath={imgUrlThumb} imgAlt={productName} />
          </ModalContent>
        {/* </Fade> */}
      </Modal>

      <img className="mx-auto h-24 w-24 bg" src={TopFlowers} alt="topFlowers" />
      {/* {JSON.stringify(id)} */}
      {/* {prodID} */}
      
      {
        newImg ? 
        (
          <>
                    
            <div className="mx-auto max-w-8xl px-6 sm:px-8 md:px-12">
              {/* <div className="grid grid-cols-1 sm:grid-cols-5 gap-5"> */}
              <div className="block md:flex prodWrapper gap-5">
                <div className="col-span-1 sm:col-span-2 grid1-item flex lg:flex-row flex-none  flex-col-reverse items-center gap-5">
                  { window.innerWidth <= 820 ?
                    <>
                      <div className="subCont-1" style={{minWidth: '100%', width: '-webkit-fill-available'}}>
                        <Swiper 
                            // loop={true}
                            modules={[Pagination, Navigation, Scrollbar]}
                            // spaceBetween={10}
                            slidesPerView={1}
                            navigation={false}
                            pagination={true}
                            scrollbar={false}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            ref={zoomSlideRef}
                            className="slideParent pb-5"
                            // style={{'--swiper-pagination-bottom': '-1rem'}}
                        >
                          
                        {
                          newImg?.map( (item, index) => {
                            // console.log('=========>>>>>>========')
                            // console.log(imgUrlThumb, item)
                            // console.log('==========<<<<<<====')
                            return (
                            <SwiperSlide key={index} onClick={handleModalOpen} className="text-center">
                              {/* {index} */}
                              {/* <div className=""> */}
                                <img
                                  onClick={() => setCount(index)}
                                  className="cursor-pointer h-full max-h-[25rem] mx-auto"
                                  // src={`https://mabesha.com/admin/storage/product/product_logo/product_image/${item}`}
                                  // src={`https://mabesha.com/admin/storage/product/product_logo${item}`}
                                  src={`${imgUrlThumb}/${item}`}
                                  alt={`${productName}-${index}`}
                                />
                              {/* </div> */}
                              </SwiperSlide>
                            );
                          } )
                        }
                        </Swiper>
                        {/* {(newImg.length > 3) ? <span className="underline text-[.8em] font-bold mt-2 block text-center">+{newImg.length - 3} more 1</span> : '' } */}
                      </div>
                      {/* <ZoomPop imgItem={newImg} imgBGPath={imgUrl} imgPath={imgUrlThumb} imgAlt={productName} /> */}
                    </>
                    :
                    <>
                      <div className="subCint-1">
                          <button className="w-full" onClick={() => zoomSlideRef.current.swiper.slidePrev()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="m-auto" viewBox="0 0 16 16">
                              <path d="M8 11a.5.5 0 0 0 .5-.5V6.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 6.707V10.5a.5.5 0 0 0 .5.5"/>
                              <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"/>
                            </svg>
                          </button>
                        <Swiper 
                            // loop={true}
                            modules={[Pagination, Navigation]}
                            spaceBetween={10}
                            slidesPerView={3}
                            // navigation={true}
                            direction={'vertical'}
                            // centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            // navigation
                            // pagination={{ clickable: true, }}
                            // modules={[Autoplay, Pagination, Navigation]}
                            className="md:h-[18em] h-auto"
                            ref={zoomSlideRef}
                        >
                          
                        {
                          newImg?.map( (item, index) => {
                            // console.log('=========>>>>>>========')
                            // console.log(imgUrlThumb, item)
                            // console.log('==========<<<<<<====')
                            return (
                            <SwiperSlide key={index} className={`${count === index ? 'activated':''}`}>
                              <div className="flex flex-wrap justify-center items-center h-full">
                                <img
                                  onClick={() => setCount(index)}
                                  className="cursor-pointer h-20 w-16 rounded-md"
                                  // src={`https://mabesha.com/admin/storage/product/product_logo/product_image/${item}`}
                                  src={`${imgUrlThumb}/${item}`}
                                  alt={`${productName}-${index}`}
                                  style={{objectFit: 'cover', objectPosition: 'top'}}
                                />
                              </div>
                            </SwiperSlide>
                              
                            );
                          } )
                        }
                        </Swiper>
                        
                            <button className="w-full" onClick={() => zoomSlideRef.current.swiper.slideNext()}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="m-auto" viewBox="0 0 16 16">
                                <path d="M8 5a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5A.5.5 0 0 1 8 5"/>
                                <path d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"/>
                              </svg>
                            </button>
                        {/* {(newImg.length > 3) ? <span className="underline text-[.8em] font-bold mt-2 block text-center">+{newImg.length - 3} more</span> : '' } */}
                      </div>
                      <div className="subCont-2 flex-grow flex-shrink-0 flex-basis-full z-[9]">
                        <div className="prodMagnific z-[9] md:m-[20px] md:mr-0 " style={{flex: '0 0 30%'}}>
                          <ReactImageMagnify {...{
                              smallImage: {
                                alt: `${productName}`,
                                isFluidWidth: true,
                                // src: `${imgUrlThumb}/${newImg[count]}`,
                                src: `${imgUrlMediumThumb}/${newImg[count]}`,
                                // srcSet: this.srcSet,
                                sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                            },
                            largeImage: {
                                src: `${imgUrl}/${newImg[count]}`,
                                width: 1200,
                                height: 1800,
                              },
                            enlargedImageStyle: {
                              objectFit: 'cover',
                              maxWidth: 'unset',
                            },
                            enlargedImageContainerDimensions: {
                                width: '200%',
                                height: '100%'
                            },
                            enlargedImageContainerClassName: 'prod-zoom',
                            isHintEnabled: true,
                            enlargedImagePosition: 'beside',
                            }}
                          />
                        </div>
                      </div>
                    </>
                  }
                    
                  
                </div>

                <div className="col-span-1 sm:col-span-3 prodDtl flex flex-1 flex-wrap lg:flex-nowrap md:pl-5">
                  <div className="grid1-item flex-auto pr-2">
                    <Fade right>
                      <h1 className=" text-2xl text-gray-800">
                        {singleProduct?.product_name}
                      </h1>
                    </Fade>
                    <Fade top>
                      <p className="text-[1rem]  my-5 text-gray-500 text-justify font-Poppins" dangerouslySetInnerHTML={{__html:singleProduct?.product_detail}}>
                      
                      </p>
                    </Fade>

                    <h1 className=" text-[1.2rem] text-gray-800">Material details:</h1>
                    {pm?.map((item, index) => {
                      return (
                        <ul key={index} className="text-1xl text-gray-500 list-disc font-Poppins  mt-2 ml-5">
                          <li className="my-0" dangerouslySetInnerHTML={{__html:item}}></li>
                        </ul>
                      );
                    })}
                    {singleProduct?.color_name ? 
                    <>
                        <h1 className=" text-2xl mt-3">Available colors: </h1>
                        <div className='space-x-3  font-Poppins text-lg  mt-0.5 ml-1'>
                            <p className=' ml-3 font-Poppins uppercase font-bold'>{singleProduct?.color_name}</p>                            
                            <button
                                  className="h-16 w-12 relative"
                                  style={{
                                      backgroundImage: `url(${imgUrlThumb}/${newImg[count]})`,
                                      backgroundSize: 'cover',
                                  }}
                              >
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 33" className="absolute inset-0 w-full h-full" >
                                      <rect x="0.5" y="0.5" width="23" height="32" stroke="green" strokeWidth="2" fill="none" />
                                  </svg>
                              </button>
                            {singleProduct?.other_products.map((item, index) => {
                              const prodTitle = singleProduct?.product_name
                              const prodColor = item.color_name
                              const slug = ((prodTitle)? prodTitle : "").replace(/\W+/g, '-') +((prodColor)? "-"+prodColor : "").replace(/\W+/g, '-')+'-'+item.id
                              const titleToLink = "/../../saree/"+slug.toLowerCase();
                              const image = `url(${imgUrlThumb}/${item.product_image.split(",")[0]})`
                              console.log("issomething"+titleToLink)
                                return(
                                  <button
                                    key={index}
                                    className="h-16 w-12 relative"
                                    style={{
                                        backgroundImage: image,
                                        backgroundSize: 'cover',
                                    }}
                                    onClick={() => navigate(titleToLink)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 33"  className="absolute inset-0 w-full h-full">
                                      <rect x="0.5" y="0.5" width="23" height="32" fill="none" />
                                    </svg>
                              </button>);
                              
                            })}
                            
                        </div>
                    </>
                    :
                    <></>
                    }
                        
                  </div>
                  <div className="grid1-item grow-0 shrink-0 basis-full md:basis-[28%] prodPrice mt-5 md:mt-0">
                    <div className="flex flex-col items-start sm:item-end">
                      <h1 className=" text-2xl font-Poppins font-semibold ">
                        {/* RS.{" "} */}
                        ₹ {" "}
                        <span className="text-gray-700 text-xl">
                          {singleProduct?.product_rate}/-
                        </span>
                      </h1>

                      { singleProduct?.is_stock_available !== 0 ?
                      <>
                        { findCartById(singleProduct?.id)<=0? 
                        <button onClick={() => addMyItem(singleProduct)}
                          className="text-sm bg-red-600 text-white font-semibold p-2 rounded-md w-32 flex  justify-around mt-3">
                          {" "}
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5" >
                            <path fillRule="evenodd" d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z" clipRule="evenodd" />
                          </svg> Add To cart
                        </button>
                        :
                        <div className="mt-5" style={{display:"flex"}}>
                          <button onClick={() => updateItemQuantity(singleProduct?.id,findCartById(singleProduct?.id)-1)} className="minus bg-red-500 border border-gray-300 rounded-md w-8 h-8 text-white focus:outline-none">-</button>
                          <p className="w-8 h-8 text-center text-xl rounded-md focus:outline-none">{findCartById(singleProduct?.id)}</p>
                          {findCartById(singleProduct.id)<singleProduct.product_count?
                          <>
                            <button onClick={() => updateItemQuantity(singleProduct?.id,findCartById(singleProduct?.id)+1)} className="plus bg-red-500 border border-gray-300 rounded-md w-8 h-8 text-white focus:outline-none" >+</button>
                          </>:
                          <>
                          <button disabled className="text-sm bg-yellow-600 text-white font-semibold p-2 rounded-md w-32 flex  justify-around">
                                    {" "}
                                    No Stock
                                  </button>
                          </>
                          }
                            
                        </div>
                        }
                          
                      </>
                      : 
                      <small>
                        <i className="text-muted">Sorry Product <b className="text-uppercase text-red-600 font-bold">Sold Out</b></i>
                      </small>
                      }
                    </div>
                  </div>


                </div>
              </div>
            </div>
      
          </>
        )
        :
        (
          <>{singleProduct}</>
        )
      }

      {/* <div className="flex justify-center my-10">
        <img src={seeSimilarSaree} alt="seseeSimilarSaree" />
      </div> */}
      <SeeSimilarSarees similarProduct={similarProduct} imgUrlThumb={imgUrlThumb} />
      <div className="flex">
        <button onClick={() => navigate('../productlist/'+categoryId)} className="text-sm bg-red-600 text-white font-semibold p-2 rounded-md w-32 mb-3 mx-auto">
          View More
        </button>
      </div>
      <SubscribeForUpdate />
    </Layout>
  );
};
const ProductDetailsPage = memo(ProductDetailsPageMemorize);
export default ProductDetailsPage;










/* const Backdrop = React.forwardRef((props, ref) => {
const { open, ...other } = props;
return <Fade ref={ref} in={open} {...other} />;
}); */

/* Backdrop.propTypes = {
open: PropTypes.bool.isRequired,
}; */

const Modal = styled(BaseModal)`
position: fixed;
z-index: 1300;
inset: 0;
display: flex;
align-items: center;
justify-content: center;
`;

/* const StyledBackdrop = styled(Backdrop)`
z-index: -1;
position: fixed;
inset: 0;
background-color: rgb(0 0 0 / 0.5);
-webkit-tap-highlight-color: transparent;
`; */

/* const Fade = React.forwardRef(function Fade(props, ref) {
const { in: open, children, onEnter, onExited, ...other } = props;
const style = useSpring({
  from: { opacity: 0 },
  to: { opacity: open ? 1 : 0 },
  onStart: () => {
    if (open && onEnter) {
      onEnter(null, true);
    }
  },
  onRest: () => {
    if (!open && onExited) {
      onExited(null, true);
    }
  },
});

return (
  <animated.div ref={ref} style={style} {...other}>
    {children}
  </animated.div>
);
}); */


/* Fade.propTypes = {
children: PropTypes.element.isRequired,
in: PropTypes.bool,
onEnter: PropTypes.func,
onExited: PropTypes.func,
}; */

const blue = {
200: '#99CCFF',
300: '#66B2FF',
400: '#3399FF',
500: '#007FFF',
600: '#0072E5',
700: '#0066CC',
};

const grey = {
50: '#F3F6F9',
100: '#E5EAF2',
200: '#DAE2ED',
300: '#C7D0DD',
400: '#B0B8C4',
500: '#9DA8B7',
600: '#6B7A90',
700: '#434D5B',
800: '#303740',
900: '#1C2025',
};

const style = {
  position: 'absolute',
  // top: '50%',
  top: '0',
  left: '50%',
  transform: 'translate(-50%, 0)',
  // width: 400,
  width: '100%',
  // height: '100%',
  height: '100vh',
};

const ModalContent = styled('div')(
({ theme }) => css`
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  text-align: start;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  // border-radius: 8px;
  border-radius: 0;
  /* border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  box-shadow: 0 4px 12px
    ${theme.palette.mode === 'dark' ? 'rgb(0 0 0 / 0.5)' : 'rgb(0 0 0 / 0.2)'}; */
  padding: 24px;
  color: ${theme.palette.mode === 'dark' ? grey[50] : grey[900]};

  & .modal-title {
    margin: 0;
    line-height: 1.5rem;
    margin-bottom: 8px;
  }

  & .modal-description {
    margin: 0;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? grey[400] : grey[800]};
    margin-bottom: 4px;
  }

  &:focus-visible {
    outline: none;
  }
`,
);

/* const TriggerButton = styled(Button)(
({ theme }) => css`
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }
`,
); */